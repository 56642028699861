import { EnvironmentConfig } from './config';
import { Environment } from '../app/services/core/service-helpers';
import { LMI_LOGIN_ED, LMI_LOGIN_PROD, LMI_LOGIN_STAGE } from '@jive/core/authentication';

const appName = 'Billing';
const clientIdDev = '90b0d897-48a0-4143-a6e4-3e15d9928387';
const clientIdStage = 'f6aeb7f4-f437-45c2-8ee8-e5662dd6adb1';
const clientIdProd = 'e3280149-1d5e-4af6-9f9a-4e33700f7f80';

const localConfig: EnvironmentConfig = {
  appName,
  environment: Environment.DEV,
  portalUrl: 'https://localhost:4200',
  clientId: clientIdDev,
  logoutBaseUrl: 'https://authenticationed1.lmiinc.test.expertcity.com/oauth/logout?client_id=' + clientIdDev + '&redirect_uri=https%3A%2F%2Flocalhost%3A4200%2Fbilling',
  loginBaseUrl: LMI_LOGIN_ED,
  redirectUri: '/billing',
  billingPortalBffUrl: 'https://api.dev.jive.com/billing-portal-bff/v1',
  jifUrl: 'https://api.dev.jive.com/jif/v4',
  stripePublishedKey: {
    US: 'pk_test_DDD1frwEkIJqtgLLDkSUdCAW',
    CA: 'pk_test_85atBOxBAzd63EZRRgKSYxlB',
    MX: 'pk_test_mO6UJAssM2qFsLGF5DQZsfrq'
  },
  googleAnalyticsKey: 'UA-81751214-2',
  cardinalLogging: 'on',
  smsDetailsLaunchDate: '2023-02-01T00:00:00Z',
  gtcBillingPortalUrl: 'https://admin.dev.gtc.goto.com/gtc-billing/invoices'
};

const devConfig: EnvironmentConfig = {
  appName,
  environment: Environment.DEV,
  portalUrl: 'https://my.dev.jive.com',
  clientId: clientIdDev,
  logoutBaseUrl: 'https://authenticationed1.lmiinc.test.expertcity.com/oauth/logout?client_id=' + clientIdDev + '&redirect_uri=https%3A%2F%2Fmy.dev.jive.com%2Fbilling%2F',
  loginBaseUrl: LMI_LOGIN_ED,
  redirectUri: '/billing/',
  billingPortalBffUrl: 'https://api.dev.jive.com/billing-portal-bff/v1',
  jifUrl: 'https://api.dev.jive.com/jif/v4',
  stripePublishedKey: {
    US: 'pk_test_DDD1frwEkIJqtgLLDkSUdCAW',
    CA: 'pk_test_85atBOxBAzd63EZRRgKSYxlB',
    MX: 'pk_test_mO6UJAssM2qFsLGF5DQZsfrq'
  },
  googleAnalyticsKey: 'UA-81751214-2',
  cardinalLogging: 'on',
  smsDetailsLaunchDate: '2023-02-15T00:00:00Z',
  gtcBillingPortalUrl: 'https://admin.dev.gtc.goto.com/gtc-billing/invoices'
};

const stagingConfig: EnvironmentConfig = {
  appName,
  environment: Environment.STAGING,
  portalUrl: 'https://my.staging.jive.com',
  clientId: clientIdStage,
  logoutBaseUrl: 'https://authenticationstage.logmeininc.com/oauth/logout?client_id=' + clientIdStage + '&redirect_uri=https%3A%2F%2Fmy.staging.jive.com%2Fbilling%2F',
  loginBaseUrl: LMI_LOGIN_STAGE,
  redirectUri: '/billing/',
  billingPortalBffUrl: 'https://api.staging.jive.com/billing-portal-bff/v1',
  jifUrl: 'https://api.staging.jive.com/jif/v4',
  stripePublishedKey: {
    US: 'pk_test_DDD1frwEkIJqtgLLDkSUdCAW',
    CA: 'pk_test_85atBOxBAzd63EZRRgKSYxlB',
    MX: 'pk_test_mO6UJAssM2qFsLGF5DQZsfrq'
  },
  googleAnalyticsKey: 'UA-81751214-2',
  cardinalLogging: 'on',
  smsDetailsLaunchDate: '2023-02-22T00:00:00Z',
  gtcBillingPortalUrl: 'https://admin.staging.gtc.goto.com/gtc-billing/invoices'
};

const prodConfig: EnvironmentConfig = {
  appName,
  environment: Environment.PROD,
  portalUrl: 'https://my.jive.com',
  clientId: clientIdProd,
  logoutBaseUrl: 'https:/authentication.logmeininc.com/oauth/logout?client_id=' + clientIdProd + '&redirect_uri=https%3A%2F%2Fmy.jive.com%2Fbilling%2F',
  loginBaseUrl: LMI_LOGIN_PROD,
  redirectUri: '/billing/',
  billingPortalBffUrl: 'https://api.jive.com/billing-portal-bff/v1',
  jifUrl: 'https://api.jive.com/jif/v4',
  stripePublishedKey: {
    US: 'pk_live_i6KRu0IObot0aNTqFUYqCpGq',
    CA: 'pk_live_H91qWWbqPXSzaFtzIUtJBT3T',
    MX: 'pk_live_DZcX8hRjNPSusHmpUoRpmlnJ'
  },
  googleAnalyticsKey: 'UA-81751214-1',
  cardinalLogging: 'off',
  smsDetailsLaunchDate: '2023-03-01T00:00:00Z',
  gtcBillingPortalUrl: 'https://admin.goto.com/gtc-billing/invoices'
};

let usedEnv: EnvironmentConfig;

switch ( ( window as any ).environment ) {
  case 'local': {
    usedEnv = localConfig;
    break;
  }
  case 'dev': {
    usedEnv = devConfig;
    break;
  }
  case 'staging': {
    usedEnv = stagingConfig;
    break;
  }
  default: {
    usedEnv = prodConfig;
    break;
  }
}

export const environment = usedEnv;
